import React from "react"
import { Link } from "gatsby"

export default function Header() {
  return (
    <header>
      <div className="tw-head">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light bg-white">

            <Link className="navbar-brand tw-nav-brand" to={"/"}>
              <img src="images/logo/logo.png" alt="Wargos Gaming Technologies"/>
            </Link>

            <button className="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">

              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="/">Inicio</Link>
                  {/*<a className="nav-link" href="index.html">*/}
                  {/*  Inicio*/}
                  {/*</a>*/}
                </li>

                <li className="nav-item dropdown">
                  <a className="nav-link" href="#" data-toggle="dropdown">
                    Productos
                    <span className="tw-indicator" style={{paddingLeft : '5px'}}>
                        <i className="fa fa-angle-down"></i>
                    </span>
                  </a>
                  <ul className="dropdown-menu tw-dropdown-menu">
                    {/*<li>*/}
                    {/*  <a href="casino-online.html">Casino Online</a>*/}
                    {/*</li>*/}
                    <li>
                      <Link to={'/'}>Cupones</Link>
                    </li>
                    <li>
                      <Link to={'/'}>Promociones</Link>
                    </li>
                    <li>
                      <Link to={'/'}>Analytics</Link>
                    </li>
                    <li>
                      <Link to={'/'}>Ludopatía</Link>
                    </li>
                  </ul>
                </li>

                {/*<li className="nav-item">*/}
                {/*  <a className="nav-link" href="acerca-de-wargos.html">*/}
                {/*    Conócenos*/}
                {/*  </a>*/}
                {/*</li>*/}

                <li className="nav-item">
                  {/*<a className="nav-link" href="contact.html">Contáctanos</a>*/}
                  <Link className="nav-link" to="/contact">Contáctanos</Link>
                </li>

              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  )
}
