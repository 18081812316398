import React from "react"
import Link from 'gatsby-link'


const contactInfo = {
  location: "San isidro, Lima - PERÚ",
  address: "Ca. Las Orquídeas 444, Piso 7.",
  cellphone: "949 685 429"
}

const SocialLinks = () => {
  return (
    <div className="footer-social-link">
      <h3>Síguenos</h3>
      <ul>
        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
      </ul>
    </div>
  )
}


export default function Footer() {
  return (
    <footer id="tw-footer" className="tw-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-4">
            <div className="tw-footer-info-box">
              <a href="index.html" className="footer-logo">
                <img src="images/footer_logo.png" alt="footer_logo" className="img-fluid"/>
              </a>
              <p className="footer-info-text">
                Tecnología que simplifica el día a día.
              </p>

              {/*<SocialLinks/>*/}

            </div>

            <div className="footer-awarad">
              <img src="images/icon/best.png" alt="Wargos Gaming Technologies"/>
              <p>Wargos Technologies 2020</p>
            </div>

          </div>
          <div className="col-md-12 col-lg-8">
            <div className="row">

              <div className="col-md-6">
                <div className="contact-us">
                  <div className="contact-icon">
                    <i className="icon icon-map2"></i>
                  </div>
                  <div className="contact-info">
                    <h3>hola@wargostec.com</h3>
                    <p>¡Escribenos!</p>
                  </div>
                </div>
              </div>

              {/*<div className="col-md-6">*/}
              {/*  <div className="contact-us contact-us-last">*/}
              {/*    <div className="contact-icon">*/}
              {/*      <i className="icon icon-phone3"></i>*/}
              {/*    </div>*/}
              {/*    <div className="contact-info">*/}
              {/*      <h3>(+51) {contactInfo.cellphone}</h3>*/}
              {/*      <p>Contáctate con nosotros</p>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}

            </div>

            <div className="row">
            {/*  <div className="col-md-12 col-lg-6">*/}
            {/*    <div className="footer-widget footer-left-widget">*/}
            {/*      <div className="section-heading">*/}
            {/*        <h3>Enlaces</h3>*/}
            {/*        <span className="animate-border border-black"></span>*/}
            {/*      </div>*/}
            {/*      <ul>*/}
            {/*        <li><a href="#">Ludopatía</a></li>*/}
            {/*        <li><a href="#">Casino Online</a></li>*/}
            {/*      </ul>*/}
            {/*      <ul>*/}
            {/*        <li><a href="#">UIF</a></li>*/}
            {/*        <li><a href="#">Bussines Intelligence</a></li>*/}
            {/*      </ul>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="col-md-12 col-lg-6">*/}
            {/*    <div className="footer-widget">*/}
            {/*      <div className="section-heading">*/}
            {/*        <h3>Información</h3>*/}
            {/*        <span className="animate-border border-black"></span>*/}
            {/*      </div>*/}
            {/*      <p>Déjanos tu correo y nos contactaremos contigo.</p>*/}
            {/*      <form action="#">*/}
            {/*        <div className="form-row">*/}
            {/*          <div className="col tw-footer-form">*/}
            {/*            <input type="email" className="form-control" placeholder="tuempresa@gmail.com"/>*/}
            {/*            <button type="submit"><i className="fa fa-send"></i></button>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </form>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            </div>
          </div>
        </div>
      </div>


      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <span>Copyright &copy; 2020, Todos los derechos Reservados WARGOS</span>
            </div>

            <div className="col-md-6">
              <div className="copyright-menu">
                <ul>
                  <li>
                    <Link to={'/'}>Inicio</Link>
                  </li>
                  {/*<li><a href="#">Servicios</a></li>*/}
                  {/*<li><a href="#">Conócenos</a></li>*/}
                  <li>
                    <Link to={'/contact'}>Contáctanos</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="back-to-top" className="back-to-top">
        <button className="btn btn-dark" title="Back to Top">
          <i className="fa fa-angle-up"></i>
        </button>
      </div>

    </footer>
  )
}
