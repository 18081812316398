import React, { useState } from "react"
import { useForm } from "react-hook-form"

const ContactForm = () => {

  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [showErr, setShowErr] = useState(false)
  const { register, reset, errors, handleSubmit } = useForm({
    mode: "onChange"
  })
  const contactUrl = "https://us-central1-wargos-prod.cloudfunctions.net/wgt-form"


  const [sended, setSended] = useState(false)

  const onSubmit = (data) => {
    console.log("abirendo")
    //setShow(true)
    setLoading(true)
    fetch(contactUrl, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    }).then(r => r.json())
      .then(res => {
        setLoading(false)
        setSended(true)
        console.log(res)
        reset()
        setShow(true)
      }).catch(err => {
      setLoading(false)
      setShowErr(true)
      console.error(err)
    })

  }

  const errStyle = {
    paddingLeft: "8px",
    color: "red"
  }

  return (
    <section id="tw-contact-us" className="tw-contact-us bg-offwhite">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="section-heading text-center">
              <h2>
                Solicita tu <span>demo</span>
              </h2>
                <h6 style={{marginTop : '12px'}}>Estamos comprometidos en la recuperación de la industria. Consulta por las tarifas promocionales y el periodo de gracia.</h6>
              <span className="animate-border border-ash ml-auto mr-auto tw-mt-20 tw-mb-40"></span>
            </div>
          </div>
        </div>

        {
          !sended ?

            <div className="contact-us-form">
              <form id="contact-form" className="contact-form" onSubmit={handleSubmit(onSubmit)} method="POST">
                <div className="row">

                  <div className="col-lg-6">
                    <div className="form-group">
                      <input className="form-control form-name" name="name" id="name" placeholder="Nombre" type="text"
                             required="" ref={register({ required: "Ingrese su nombre" })}/>

                      {errors.name && <p style={errStyle}>{errors.name.message}</p>}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <input className="form-control form-subject" placeholder="Empresa" name="company"
                             type="text" ref={register({ required: "Este campo es necesario" })}/>

                      {errors.company && <p style={errStyle}>{errors.company.message}</p>}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <input className="form-control form-email" name="email" placeholder="Correo" type="email"
                             required="" ref={register({
                        required: "Un email es necesario",
                        pattern: {
                          value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          message: "Ingrese un email valido"
                        }
                      })}/>

                      {errors.email && <p style={errStyle}>{errors.email.message}</p>}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <input className="form-control form-phone" name="phone" placeholder="Teléfono (opcional)"
                             type="phone" ref={register({ required: false })}/>

                      {errors.phone && <p style={errStyle}>{errors.phone.message}</p>}
                    </div>
                  </div>


                  <div className="col-lg-12">
                    <div className="form-group">
                    <textarea className="form-control form-message required-field" id="message"
                              placeholder="Mensaje (opcional)"
                              name="message"
                              rows="2" ref={register}>
                    </textarea>
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <button className="btn btn-primary tw-mt-30" type="submit" disabled={loading}>
                    {loading ? "Enviando..." : "Enviar"}
                  </button>
                </div>

                {showErr &&
                  <h4 style={{color : 'red'}}>Hubo un error por favor intentalo de nuevo</h4>
                }

              </form>
            </div>

            :

            <div style={{marginBottom : '30px', alignItems : 'center'}}>
              <h2 style={{ color : '#fa6742'}}>Hemos recibido tu mensaje. Te contacteremos en menos de 24 horas.</h2>
            </div>

        }



      </div>

      {/*<SweetAlert*/}
      {/*  show={show}*/}
      {/*  title="¡ Gracias !"*/}
      {/*  icon='success'*/}
      {/*  text="Nos contactaremos con usted en menos de 24 horas"*/}
      {/*  onConfirm={() => setShow(false)}*/}
      {/*/>*/}

      {/*<SweetAlert*/}
      {/*  show={showErr}*/}
      {/*  title="Ups!"*/}
      {/*  icon='warning'*/}
      {/*  text="Hubo un error, por favor intentelo de nuevo."*/}
      {/*  onConfirm={() => setShowErr(false)}*/}
      {/*/>*/}
    </section>
  )
}
export default ContactForm
